import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {switchMap, map} from "rxjs";
import {ParametrageDebogageServeurService} from "@core/backend/services/shared/parametrage-debogage-serveur.service";
import {globalActions} from "@way/app/store/global.actions";
import {WayState} from "@way/app/store/way-state";
import {MatDialog} from "@angular/material/dialog";
import {
  ParametrageDebogagePopupComponent
} from "@way/app/parametrage-debogage-popup/parametrage-debogage-popup.component";
import {parametrageDebogageActions} from "@way/app/parametrage-debogage-popup/store/parametrage-deboggage-serveur.actions";
import {
  parametrageDeboggageServeurFeature
} from "@way/app/parametrage-debogage-popup/store/parametrage-deboggage-serveur.feature";

export const ouvrirPopupParametrageDebogage = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store<WayState>),
    parametrageDebogageServeurService = inject(ParametrageDebogageServeurService),
    matDialog = inject(MatDialog)
  ) => {
    return actions$.pipe(
      ofType( globalActions.utilisateurACliqueSurBoutonParametrageDebogage ),
      map(()=>{
        return matDialog.open(ParametrageDebogagePopupComponent);
      }),
      switchMap((dialogRef)=>{
        return actions$.pipe(
          ofType( parametrageDebogageActions.utilisateurACliqueSurBoutonSauvegarder ),
          concatLatestFrom(() => store.select(parametrageDeboggageServeurFeature.selectDonnees)),
          switchMap(([, parametrageDebogage])=>{

            return parametrageDebogageServeurService.updateParametrage(parametrageDebogage!).pipe(
              map((parametrage) => {
                store.dispatch(parametrageDebogageActions.aPIAReponduAvecParametrageDebogageServeur({parametrage: parametrage}));
                dialogRef.close();
              }),
            );
          })
        )
      })
    );
  }, {functional: true, dispatch:false}
);
