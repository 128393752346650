<ng-container *ngrxLet="{parametrageDebogage : parametrageDebogage$, isUpdating:isUpdating$} as model">
<h2 mat-dialog-title>Débogage</h2>
<mat-dialog-content>
  <mat-list>
    <div mat-subheader>Paramétrage serveur</div>
    <mat-list-item>
      <mat-icon matListItemIcon>mail</mat-icon>
      <div matListItemTitle>Envoi des mails via Brevo</div>
      <div matListItemLine>Si coché, alors les mails partiront via Brevo. Sinon, les mails iront directement dans MailCatcher</div>
      <mat-slide-toggle [checked]="model.parametrageDebogage!.isFakingEmailBrevo !== true" (change)="onUtilisateurToggleSurEmailBrevo()" matListItemMeta></mat-slide-toggle>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button  [disabled]="model.isUpdating" (click)="onUtilisateurclicksurAnnuler()">Annuler</button>
  <button mat-button  color="primary" [disabled]="model.isUpdating" (click)="onUtilisateurclicksurSauvegarder()">Sauvegarder</button>
</mat-dialog-actions>
</ng-container>
