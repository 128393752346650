export interface LayoutFeatureState {
  /**
   * Si vrai, alors un spinner modal est affiché pour bloquer toute manipulation utilisateur.
   * Utilisé lors de traitement longs (appels API, etc.)
   */
  spinnerModalAffiched: boolean;
  etatSlider: {
    devis: null | 'active' | 'done',
    souscription: null | 'active' | 'done',
    paiement: null | 'active' | 'done',
  },
}

export const initialState: LayoutFeatureState={
  spinnerModalAffiched: false,
  etatSlider: {
    devis: 'active',
    souscription: null,
    paiement: null
  },
}
