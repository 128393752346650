import {inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {WayState} from './store/way-state';
import {combineLatest, Observable, tap} from 'rxjs';
import {globalActions} from './store/global.actions';
import {PaysService, ReferentielProduitService} from "@core/backend/services";
import {ParametrageDebogageServeurService} from "@core/backend/services/shared/parametrage-debogage-serveur.service";
import {ReferentielProduitReferentielWayResource} from "@core/backend/models/referentiel";
import {IndicatifTelephoniqueService} from "@core/backend/services/referentiel/indicatif-telephonique.service";

export function ChargerReferentielAuDemarrageDeLapplicationFactory(
  store = inject((Store<WayState>)),
  paysService = inject(PaysService),
  exploitationParametrageService = inject(ParametrageDebogageServeurService),
  referentielProduitService = inject(ReferentielProduitService),
  indicatifTelephoniqueService = inject(IndicatifTelephoniqueService)
): () => Observable<never> {
  return () => {
    return combineLatest([
      paysService.getPays$(),
      exploitationParametrageService.getParametrage(),
      referentielProduitService.getReferentielProduits$<ReferentielProduitReferentielWayResource>(),
      indicatifTelephoniqueService.getIndicatifsTelephoniques$()
    ]).pipe(
      tap(([listePays, parametrage, referentielProduit, indicatifsTelephoniques]) => {
        store.dispatch(globalActions.referentielCharged({
          referentiel: {listePays: listePays, produit:referentielProduit[0], indicatifsTelephoniques: indicatifsTelephoniques},
          parametrageDebogageServeur: parametrage,
        }));
      }),
    ) as Observable<never>;
  };
}
