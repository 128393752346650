import { MetaReducer } from '@ngrx/store';
import { logActionsToConsoleMetareducer } from './log-actions-to-console.metareducer';
import { isDevMode } from '@angular/core';

/**
 * Interface de base de toutes les interfaces de state des différentes applications
 */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CoreState{}

/**
 * Liste des meta reducers : fonctions qui vont intercepter les actions et les états
 */
export function getCoreMetaReducers<TState extends CoreState>(): MetaReducer<TState>[] {
  if (isDevMode()) return [logActionsToConsoleMetareducer];
  return [];
}
