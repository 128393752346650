import {Component, OnInit} from '@angular/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent, MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {Store} from "@ngrx/store";
import {WayState} from "@way/app/store/way-state";
import {LetDirective} from "@ngrx/component";
import {first, tap} from "rxjs";
import {ParametrageDebogageServeurResource} from "@core/backend/models/shared";
import {parametrageDeboggageServeurFeature} from "@way/app/parametrage-debogage-popup/store/parametrage-deboggage-serveur.feature";
import {parametrageDebogageActions} from "@way/app/parametrage-debogage-popup/store/parametrage-deboggage-serveur.actions";

@Component({
  selector: 'way-parametrage-debogage-popup',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    MatDialogTitle,
    MatListModule,
    MatIconModule,
    MatSlideToggleModule,
    LetDirective
  ],
  templateUrl: './parametrage-debogage-popup.component.html',
  styleUrl: './parametrage-debogage-popup.component.scss'
})
export class ParametrageDebogagePopupComponent implements OnInit{

  protected parametrageDebogage$ = this.store.select(parametrageDeboggageServeurFeature.selectDonnees);
  protected isUpdating$ = this.store.select(parametrageDeboggageServeurFeature.selectIsUpdatingParametrageDebogageServeur);

  private parametrageInitial?: ParametrageDebogageServeurResource;

  constructor(private store:Store<WayState>, public dialogRef: MatDialogRef<ParametrageDebogagePopupComponent>){
  }

  public ngOnInit(){
    this.parametrageDebogage$.pipe(first(), tap((parametrageInitial)=>{
      this.parametrageInitial = parametrageInitial!;
    })).subscribe()
  }

  onUtilisateurclicksurSauvegarder() {
    this.store.dispatch(parametrageDebogageActions.utilisateurACliqueSurBoutonSauvegarder());

  }

  onUtilisateurclicksurAnnuler() {
    this.store.dispatch(parametrageDebogageActions.utilisateurACliqueSurBoutonAnnuler({parametrageInitial:this.parametrageInitial!}))
    this.dialogRef.close();
  }

  onUtilisateurToggleSurEmailBrevo() {
    this.store.dispatch(parametrageDebogageActions.utilisateurAToggledEmailBrevo());
  }

}
