import {createFeature, createReducer, on} from "@ngrx/store";
import {initialState} from "@way/app/layout/store/layout.state";
import {devisActions} from "@way/app/devis/store/devis.actions";
import {monTarifActions} from "@way/app/devis/mon-tarif/store/mon-tarif.actions";
import {
  etapeValidationEmailActions
} from "@way/app/souscription/etape-validation-email/store/etape-validation-email.actions";
import {
  etapeConfirmationEmailEnvoyeActions
} from "@way/app/souscription/etape-confirmation-email-envoye/store/etape-confirmation-email-envoye.actions";
import {
  etapeRecapitulatifSouscriptionActions
} from "@way/app/souscription/etape-recapitulatif-souscription/store/etape-recapitulatif-souscription.actions";
import {recueilActions} from "@way/app/souscription/recueil/store/recueil.actions";
import {etapePaiementActions} from "@way/app/paiement/etape-paiement/store/etape-paiement.actions";
import {etapePaiementSuccesActions} from "@way/app/paiement/etape-paiement-succes/store/etape-paiement-succes.actions";

export const layoutFeature = createFeature({
  name: 'layout',
  reducer: createReducer(initialState,
    on(devisActions.utilisateurANavigueVersDevis, (state) => {
      return {
        ...state,
        spinnerModalAffiched: false,
        etatSlider: {
          devis: 'active' as const,
          souscription: null,
          paiement: null,
        }
      };
    }),
    on(
      monTarifActions.utilisateurANavigueVersMonTarifExistant, monTarifActions.utilisateurANavigueVersMonTarifVierge,
      recueilActions.utilisateurANavigueVersEcranRecueil,
      etapeConfirmationEmailEnvoyeActions.utilisateurANavigueVersEcranConfirmationEmailEnvoye,
      etapeValidationEmailActions.utilisateurACliqueSurRecevoirMonEmailDeValidation,
      etapeRecapitulatifSouscriptionActions.utilisateurACliqueSurPayer,
      etapePaiementActions.utilisateurACliqueSurPayer,
      etapePaiementActions.utilisateurANavigueVersEcranEtapePaiement,
      etapePaiementSuccesActions.utilisateurANavigueVersEcranEtapePaiementSucces,
      etapeValidationEmailActions.utilisateurANavigueVersEcranValidationEmail,
      (state, action) => {

        let nextState = {
          ...state,
          spinnerModalAffiched: true
        }

        if (action.type === recueilActions.utilisateurANavigueVersEcranRecueil.type) {
          nextState = {
            ...nextState,
            etatSlider: {
              devis: 'done',
              souscription: 'active',
              paiement: null
            }
          };
        }
        if (action.type === etapePaiementActions.utilisateurANavigueVersEcranEtapePaiement.type) {
          nextState = {
            ...nextState,
            etatSlider: {
              devis: 'done',
              souscription: 'done',
              paiement: 'active'
            }
          };
        }
        if (action.type === etapePaiementSuccesActions.utilisateurANavigueVersEcranEtapePaiementSucces.type) {
          nextState = {
            ...nextState,
            etatSlider: {
              devis: 'done',
              souscription: 'done',
              paiement: 'done'
            }
          };
        }

        return nextState;
      }
    ),
    on(
      monTarifActions.apiARenvoyeDevis, monTarifActions.apiAIndiqueDevisInexistant, monTarifActions.apiAIndiqueDevisExpire, monTarifActions.apiAIndiqueDevisObsolete, monTarifActions.apiARenvoyeErreur,
      recueilActions.aPIAFourniSouscription, recueilActions.apiAIndiqueSouscriptionInexistant, recueilActions.apiAIndiqueSouscriptionExpire, recueilActions.apiAIndiqueSouscriptionObsolete, recueilActions.aPIARenvoyeUneErreurLorsDeRecuperationSouscription,
      etapeConfirmationEmailEnvoyeActions.aPIAFourniSouscription, etapeConfirmationEmailEnvoyeActions.apiAIndiqueSouscriptionInexistant, etapeConfirmationEmailEnvoyeActions.apiAIndiqueSouscriptionExpire, etapeConfirmationEmailEnvoyeActions.apiAIndiqueSouscriptionObsolete, etapeConfirmationEmailEnvoyeActions.aPIARenvoyeUneErreurLorsDeRecuperationSouscription,
      etapeValidationEmailActions.aPIAIndiqueAvoirEnvoyeEmailDeValidation, etapeValidationEmailActions.aPIARenvoyeUneErreurInattenduePendantEmailDeValidation,
      etapeRecapitulatifSouscriptionActions.apiARenvoyeOk, etapeRecapitulatifSouscriptionActions.apiARenvoyeErreur,
      etapePaiementActions.aPIAReponduSuiteADeclenchementPaiement, etapePaiementActions.aPIAFourniSouscription, etapePaiementActions.aPIARenvoyeUneErreurLorsDeRecuperationSouscription,
      etapePaiementSuccesActions.aPIAFourniSouscription, etapePaiementSuccesActions.aPIARenvoyeUneErreurLorsDeRecuperationSouscription,
      etapeValidationEmailActions.aPIRenvoyeLeDevis, etapeValidationEmailActions.aPIARenvoyeUneErreurInattenduePendantRecuperationDevis,
      (state) => {
        return {
          ...state,
          spinnerModalAffiched: false,
        };
      }
    )
  )
});
