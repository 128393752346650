import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'way-contact-bottom-sheet',
  templateUrl: './contact-bottom-sheet.component.html',
  styleUrls: ['./contact-bottom-sheet.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    RouterLink
  ],
  standalone: true
})
export class ContactBottomSheetComponent {

  /**
   *
   * @param bottomSheetRef reference vers le composant qui a ouvert le bottomsheet.
   */
  constructor(private bottomSheetRef: MatBottomSheetRef) {


  }

  openLink(): void {
     this.bottomSheetRef?.dismiss();
  }
}
