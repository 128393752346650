<header class="way-header">

  <div class="way-header-logo">
    <div class="way-header-logo-msh">MSH</div>
    <div class="way-header-logo-offre" [ngClass]="{empty:(nomCommercial$|async)==null }">
      <span>{{ nomCommercial$|async }}</span></div>
  </div>

  <div class="way-header-stepper">
    <ol class="way-stepper">
      <li [class]="(selectEtatSlider$|async)!.devis" i18n="@@App.ProgressBar.Devis">Devis</li>
      <li [class]="(selectEtatSlider$|async)!.souscription" i18n="@@App.ProgressBar.Souscription">Adhésion</li>
      <li [class]="(selectEtatSlider$|async)!.paiement" i18n="@@App.ProgressBar.Paiement">Paiement</li>
    </ol>
  </div>

  <button class="way-header-lang" mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="country-flag">{{ currentLanguage | codeToFlagEmoji }}</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="changeLangue('fr')">
      <mat-icon class="country-flag">{{ 'FR' | codeToFlagEmoji }}</mat-icon>
      <span i18n="@@French">Français</span>
    </button>
    <button mat-menu-item (click)="changeLangue('en-gb')">
      <mat-icon class="country-flag">{{ 'GB' | codeToFlagEmoji }}</mat-icon>
      <span i18n="@@English">Anglais</span>
    </button>
  </mat-menu>
</header>

<div class="way-wrapper" [ngClass]="{'afficherHelpDesk': (afficherHelpDesk$|async)}">

  <div class="way-content">
    <router-outlet></router-outlet>
  </div>

  <aside role="presentation" class="way-helpdesk">
    <div class="way-helpdesk-header">
      <h3 i18n="@@App.Aide.Title">Besoin d'aide ?</h3>
      <p i18n="@@App.Aide.Description">
        Nos conseillers sont disponibles du lundi au vendredi, de 9h à 12h et de 13h à 18h (CET)
      </p>
    </div>
    <div class="way-helpdesk-call">
      <a href="tel:+33144204877" mat-stroked-button>
        <mat-icon>call</mat-icon>
        +33 1 44 20 48 77
      </a>
      <span i18n="@@App.Aide.Appel.Info">Appel non surtaxé</span>
    </div>
    <div class="way-helpdesk-separator">
      <span i18n="@@App.Aide.Ou">Ou</span>
    </div>
    <div class="way-helpdesk-callback">
      <a routerLink="/demande-rappel" mat-stroked-button i18n="@@App.Aide.Rappel">
        Être Rappelé·e
      </a>
    </div>
  </aside>

</div>

<div class="footer-legal">
  <a (click)="onUtilisateurACliqueSurMentionsLegales()" class="mentions" routerLink="/mentions-legales"
     i18n="@@App.Footer.MentionsLegales">Mentions légales</a>
  <a routerLink="/cgu" (click)="onUtilisateurACliqueSurCGU()" class="cgu" i18n="@@App.Footer.CGU">CGU</a>
  <a routerLink="/rgpd" (click)="onUtilisateurACliqueSurRGPD()" class="rgpd" i18n="@@App.Footer.RGPD">Traitement des
    données personnelles</a>
  <em class="version">{{version}}</em>
</div>


<div class="way-helpmobile-cta">
  <!-- eslint-disable  @angular-eslint/template/click-events-have-key-events -->
  <!-- eslint-disable   @angular-eslint/template/interactive-supports-focus -->
  <div class="way-helpmobile-cta-btn" (click)="onPhoneClick($event)">
    <div class="way-helpmobile-cta-btn-content">
      <h5 i18n="@@App.Aide.Mobile.Besoin">Besoin d'aide ?</h5>
      <p i18n="@@App.Aide.Mobile.Appel">Appeler ou me faire rappeler</p>
    </div>
    <mat-icon>call</mat-icon>
  </div>
</div>
<!-- eslint-enable   @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-enable  @angular-eslint/template/click-events-have-key-events -->

<div id="spinnercontainer" *ngIf="displaySpinner$|async">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>


<div role="presentation" class="exploitationContainer" *ngrxLet="resumeParametrageDebogage$ as parametrage">
  <div *ngIf="parametrage.parametrageVisible">
    <div class="ct">
      <button mat-mini-fab *ngIf="linksDebogage" [matMenuTriggerFor]="linksDebugMenu" [color]="''">
        <mat-icon>link</mat-icon>
      </button>
      <button class="copierJournalButton" mat-mini-fab title="erreurs Javascript" *ngrxLet="errorCount$  as errorCount"
              [color]="errorCount>0 ? 'warn':''" [matBadge]="errorCount>0 ? errorCount : null"
              (click)="onUtilisateurACliqueSurErreurs()">
        <mat-icon>file_copy</mat-icon>
      </button>

      <button class="ouvertureParametrageDebogage" mat-mini-fab title="Paramétrage de débogage"
              [color]="parametrage.hasAnyWarning ? 'warn':''"
              (click)="onDebugParameterClick($event)">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #linksDebugMenu="matMenu">
        <a [href]="lien.url" mat-menu-item *ngFor="let lien of linksDebogage" target="_blank">
          {{ lien.title }}
        </a>
      </mat-menu>
    </div>
  </div>
</div>
