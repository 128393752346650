import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { from, map, merge, switchMap } from "rxjs";
import { devisActions } from "@way/app/devis/store/devis.actions";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ErreurPopupComponent } from "@way/app/shared/popup-component/erreur-popup/erreur-popup.component";
import { MatDialog } from "@angular/material/dialog";
import {monTarifActions} from "@way/app/devis/mon-tarif/store/mon-tarif.actions";
import {
  etapeConfirmationEmailEnvoyeActions
} from "@way/app/souscription/etape-confirmation-email-envoye/store/etape-confirmation-email-envoye.actions";
import {recueilActions} from "@way/app/souscription/recueil/store/recueil.actions";
import {etapePaiementActions} from "@way/app/paiement/etape-paiement/store/etape-paiement.actions";
import {etapePaiementSuccesActions} from "@way/app/paiement/etape-paiement-succes/store/etape-paiement-succes.actions";

export const gestionErreurEffect = createEffect(
  (
    actions$ = inject(Actions),
    router = inject(Router),
    store = inject(Store),
    dialog = inject(MatDialog)
  ) => {
    return merge(
      actions$.pipe(
        ofType(
          monTarifActions.apiAIndiqueDevisExpire,

          recueilActions.apiAIndiqueSouscriptionExpire,
          etapeConfirmationEmailEnvoyeActions.apiAIndiqueSouscriptionExpire,

          etapePaiementSuccesActions.apiAIndiqueSouscriptionExpire,
          etapePaiementActions.apiAIndiqueSouscriptionExpire,
        ),
        map(() => {
          return {
            title: $localize`:@@titre_avertissement_devis_expire:Votre devis est expiré.`,
            explication: $localize`:@@message_avertissement_devis_expire:Votre devis est expiré. Veuillez saisir une nouvelle demande.`
          }
        })
      ),

      actions$.pipe(
        ofType(
          monTarifActions.apiAIndiqueDevisObsolete,

          recueilActions.apiAIndiqueSouscriptionObsolete,
          etapeConfirmationEmailEnvoyeActions.apiAIndiqueSouscriptionObsolete,

          etapePaiementSuccesActions.apiAIndiqueSouscriptionObsolete,
          etapePaiementActions.apiAIndiqueSouscriptionObsolete,
        ),
        map(() => {
          return {
            title: $localize`:@@titre_avertissement_devis_obsolete:Votre devis est obsolète.`,
            explication: $localize`:@@message_avertissement_devis_obsolete:Les dates de votre séjour ne sont plus valables. Veuillez saisir une nouvelle demande.`
          }
        })
      ),
      actions$.pipe(
        ofType(
          monTarifActions.apiNaPasleTarif
        ),
        map(() => {
          return {
            title: $localize`:@@titre_avertissement_tarification_impossible:Erreur lors de la tarification.`,
            explication: $localize`:@@message_avertissement_tarification_impossible:Nous ne disposons pas encore des informations tarifaires pour votre voyage, merci de réessayer plus tard ou de contacter le service client.`
          }
        })
      ),
      actions$.pipe(
        ofType(
          monTarifActions.apiARenvoyerErreurTarif
        ),
        map(() => {
          return {
            title: $localize`:@@titre_avertissement_tarification_erreur:Erreur lors de la tarification.`,
            explication: $localize`:@@message_avertissement_tarification_erreur:Une erreur inattendue a eu lieu lors de la tarification, merci de réessayer plus tard ou de contacter le service client.`
          }
        })
      ),
      actions$.pipe(
        ofType(
          monTarifActions.apiAIndiqueDevisInexistant,
          monTarifActions.apiARenvoyeErreur,

          recueilActions.apiAIndiqueSouscriptionInexistant,
          etapeConfirmationEmailEnvoyeActions.apiAIndiqueSouscriptionInexistant,

          etapePaiementSuccesActions.apiAIndiqueSouscriptionInexistant,
          etapePaiementActions.apiAIndiqueSouscriptionInexistant,
        ),
        map(() => {
          return {
            title: $localize`:@@titre_avertissement_erreur_inattendue:Une erreur inattendue est survenue.`,
            explication: ''
          }
        })
      )
    ).pipe(
      switchMap(({ title, explication }) => {

        const subtitle = "";
        const button = $localize`:@@bouton_avertissement_erreur_inattendue:Continuer`;

        dialog.open(ErreurPopupComponent, {
          maxWidth: "revert-layer",
          panelClass: "popupErreurPayement",
          data: {
            img: "assets/ico-card-error.svg",
            title: title,
            subtitle: subtitle,
            explication: explication,
            buttonLabel: button
          }
        })

        store.dispatch(devisActions.utilisateurANavigueVersDevis({ raison: 'accesARecueil', motifPreselectionne: null, codePaysDomicileSelectionnables: null }));

        return from(router.navigate(["devis", "recueil"]));
      })
    );
  }, { functional: true, dispatch: false }
);
