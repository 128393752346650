import { ActionReducer } from '@ngrx/store';
import { getDifferenceEntreDeuxObjets } from '../helpers/object.helpers';
import { CoreState } from './core.state';

export function logActionsToConsoleMetareducer<TState extends CoreState>(reducer: ActionReducer<TState>): ActionReducer<TState> {
  return (stateBefore, action) => {
    const stateAfter = reducer(stateBefore, action);
    console.groupCollapsed(action.type);
    console.log(`%c prev state`, `color: #9E9E9E; font-weight: bold`, stateBefore);
    console.log(`%c action`, `color: #03A9F4; font-weight: bold`, action);
    if (stateBefore != null && stateBefore != stateAfter) {
      const delta = getDifferenceEntreDeuxObjets(stateBefore as Record<string, unknown>, stateAfter as Record<string, unknown>);
      console.log(`%c delta`, `color: #FF8000; font-weight: bold`, delta);
    }
    console.log(`%c next state`, `color: #4CAF50; font-weight: bold`, stateAfter);
    console.groupEnd();
    return stateAfter;
  };
}
