import {inject} from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {EMPTY, from, mergeMap} from 'rxjs';
import {DataLayerManagementService} from '@core/marketing/data-layer-management.service';
import {Store} from '@ngrx/store';
import {souscriptionFeature} from '@way/app/souscription/store/souscription.feature';
import cryptoHelpers from '@core/helpers/crypto.helpers';
import {globalFeature} from "@way/app/store/global.feature";
import {etapeDemandeRappelActions} from "@way/app/demande-rappel/store/demande-rappel.actions";
import {devisMonTarifFeature} from "@way/app/devis/mon-tarif/store/mon-tarif.feature";

// noinspection JSUnusedGlobalSymbols
export const injecterEvenementsDansDataLayer = createEffect(
    (
        actions$ = inject(Actions),
        dataLayerManagementService = inject(DataLayerManagementService),
        store = inject(Store)
    ) => {
        return actions$.pipe(
            ofType(
                etapeDemandeRappelActions.utilisateurACliquerSurDemandeDeRappel,
                etapeDemandeRappelActions.utilisateurACliquerSurValider,
                etapeDemandeRappelActions.utilisateurACliquerSurBesoinAideMobile
            ),
            concatLatestFrom(() => [
                store.select(globalFeature.selectNomCommercial),
                store.select(souscriptionFeature.selectSouscriptionEnCours),
                store.select(devisMonTarifFeature.selectMonTarif)
            ]),
            mergeMap(([
                action,
                nomCommercial,
                souscriptionEnCours,
                monTarif
            ]) => {
                if (action.type == etapeDemandeRappelActions.utilisateurACliquerSurBesoinAideMobile.type) {
                    return cryptoHelpers.hashSHA256(souscriptionEnCours ? souscriptionEnCours.emailContactInitial : '').pipe(
                        mergeMap((sha) => {
                            let quote_id = null
                            if (souscriptionEnCours && souscriptionEnCours.devisId != null) {
                                quote_id = souscriptionEnCours.devisId;
                            } else if(monTarif && monTarif.devis?.id != null) {
                                quote_id = monTarif.devis?.id;
                            }

                            return dataLayerManagementService.pushTag({
                                'event': 'form_call_back',
                                'step_section': 'Help',
                                'step_name': 'display_help',
                                'step_number': '100',
                                'tunnel_name': nomCommercial,
                                'quote_id': quote_id,
                                'subscription_id': souscriptionEnCours?.identifiantBackOffice,
                                'email_encoded_sha256': souscriptionEnCours ? souscriptionEnCours.emailContactInitial ? sha : null : null,
                            });
                        })
                    );
                } else if (action.type == etapeDemandeRappelActions.utilisateurACliquerSurDemandeDeRappel.type) {

                    return cryptoHelpers.hashSHA256(souscriptionEnCours ? souscriptionEnCours.emailContactInitial : '').pipe(
                        mergeMap((sha) => {
                            let quote_id = null
                            if (souscriptionEnCours && souscriptionEnCours.devisId != null) {
                                quote_id = souscriptionEnCours.devisId;
                            } else if(monTarif && monTarif.devis?.id != null) {
                                quote_id = monTarif.devis?.id;
                            }
                            return dataLayerManagementService.pushTag({
                                'event': 'form_call_back',
                                'step_section': 'Help',
                                'step_name': 'call_back',
                                'step_number': '101',
                                'tunnel_name': nomCommercial,
                                'quote_id': quote_id,
                                'subscription_id': souscriptionEnCours?.identifiantBackOffice,
                                'email_encoded_sha256': souscriptionEnCours ? souscriptionEnCours.emailContactInitial ? sha : null : null,
                            });
                        })
                    );
                } else if (action.type == etapeDemandeRappelActions.utilisateurACliquerSurValider.type) {
                    return from(cryptoHelpers.hashSHA256(souscriptionEnCours ? souscriptionEnCours.emailContactInitial : '')).pipe(
                        mergeMap((sha) => {
                            let quote_id = null
                            if (souscriptionEnCours && souscriptionEnCours.devisId != null) {
                                quote_id = souscriptionEnCours.devisId;
                            } else if(monTarif && monTarif.devis?.id != null) {
                                quote_id = monTarif.devis?.id;
                            }

                            return dataLayerManagementService.pushTag({
                                'event': 'form_call_back',
                                'step_section': 'Help',
                                'step_name': 'validate_form',
                                'step_number': '102',
                                'tunnel_name': nomCommercial,
                                'quote_id': quote_id,
                                'subscription_id': souscriptionEnCours?.identifiantBackOffice,
                                'email_encoded_sha256': souscriptionEnCours ? souscriptionEnCours.emailContactInitial ? sha : null : null,
                            });
                        })
                    );
                }

                return EMPTY;
            })
        );
    },
    { functional: true, dispatch: false });
