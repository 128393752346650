import {JsonValueOf} from "@core/helpers/json-manipulation.helpers";
import {ParametrageDebogageServeurResource} from "@core/backend/models/shared";

export interface ParametrageDeboggageServeurFeatureState{
  donnees: JsonValueOf<ParametrageDebogageServeurResource> | null,
  isUpdatingParametrageDebogageServeur: boolean,
}

export const initialState: ParametrageDeboggageServeurFeatureState = {
  donnees: null,
  isUpdatingParametrageDebogageServeur: false,
};


