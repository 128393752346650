import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {JsonValueOf} from "@core/helpers/json-manipulation.helpers";
import {ParametrageDebogageServeurResource} from "@core/backend/models/shared";

export const parametrageDebogageActions = createActionGroup({
  source: '[Global/ParametrageDebogageActions]',
  events: {
    'utilisateurAToggledEmailBrevo': emptyProps(),
    'utilisateurACliqueSurBoutonSauvegarder': emptyProps(),
    'utilisateurACliqueSurBoutonAnnuler': props<{
      parametrageInitial: JsonValueOf<ParametrageDebogageServeurResource>
    }>(),
    'APIAReponduAvecParametrageDebogageServeur': props<{
      parametrage: JsonValueOf<ParametrageDebogageServeurResource>
    }>()
  }
});
