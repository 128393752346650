import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BASE_API_URL } from "../base-api.url";
import {Observable} from "rxjs";
import { ReferentielProduitReferentielBaseResource } from "../../models/referentiel";
import { ReferentielProduitResource } from "../../models/referentiel";
import { JsonValueOf } from "@core/helpers/json-manipulation.helpers";

@Injectable({
  providedIn: "root"
})
export class ReferentielProduitService {

  constructor(private httpClient: HttpClient, @Inject(BASE_API_URL) private baseUrl: string) {

  }

  public getReferentielProduits$<TReferentiel extends ReferentielProduitReferentielBaseResource>(): Observable<Array<JsonValueOf<ReferentielProduitResource<TReferentiel>>>> {
      return this.httpClient.get<Array<JsonValueOf<ReferentielProduitResource<TReferentiel>>>>(`${this.baseUrl}referentiel-produits`, {
        responseType: "json",
        headers: {"Accept": "application/json"}
      });
  }
}




