import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, merge } from 'rxjs';
import { ScrollToTargetService, scrollToTop } from "@core/rxjs/scroll-to-target.service";

import { globalActions } from '@way/app/store/global.actions';

export const gererScroll = createEffect(
  (
    actions$ = inject(Actions),
    scrollToTargetService = inject(ScrollToTargetService)
  ) => {

    return merge(

      actions$.pipe(
        ofType(globalActions.utilisateurACliqueSurCGU, globalActions.utilisateurACliqueSurMentionsLegales, globalActions.utilisateurACliqueSurRGPD),
        map(() => scrollToTop())
      ),

    ).pipe(
      scrollToTargetService.scrollToTarget()
    );
  },
  { functional: true, dispatch: false });
