import { Inject, Injectable } from "@angular/core";
import {Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BASE_API_URL } from "../base-api.url";
import {IndicatifTelephoniqueResource} from "@core/backend/models/referentiel";

@Injectable({
  providedIn: 'root',
})
export class IndicatifTelephoniqueService {

  constructor(private httpClient: HttpClient, @Inject(BASE_API_URL) private baseUrl: string){

  }

  public getIndicatifsTelephoniques$(): Observable<IndicatifTelephoniqueResource[]> {
      return this.httpClient.get<IndicatifTelephoniqueResource[]>(`${this.baseUrl}indicatifs-telephoniques`, {
        responseType: 'json',
        headers: {'Accept': 'application/json'}
      });
  }
}



