import { Inject, Injectable } from "@angular/core";
import {Observable} from "rxjs";
import {Pays} from "../../models/referentiel";
import { HttpClient } from "@angular/common/http";
import { BASE_API_URL } from "../base-api.url";

@Injectable({
  providedIn: 'root',
})
export class PaysService {

  constructor(private httpClient: HttpClient, @Inject(BASE_API_URL) private baseUrl: string){

  }

  public getPays$(): Observable<Pays[]> {
      return this.httpClient.get<Pays[]>(`${this.baseUrl}pays`, {
        responseType: 'json',
        headers: {'Accept': 'application/json'}
      });
  }
}



