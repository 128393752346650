import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BASE_API_URL} from "../base-api.url";
import {Observable} from "rxjs";
import {JsonValueOf} from "@core/helpers/json-manipulation.helpers";
import {DemandeRappelResource} from "@core/backend/models/shared/demandeRappelResource";

@Injectable({
  providedIn: "root"
})
export class DemandeRappelService {

  constructor(private httpClient: HttpClient, @Inject(BASE_API_URL) private baseUrl: string) {

  }

  public postDemandeRappel(demandeRappel: JsonValueOf<DemandeRappelResource>): Observable<object> {
      return this.httpClient.post(`${this.baseUrl}demande-rappel`, demandeRappel, {
        responseType: "json",
        headers: {"Accept": "application/json"}
      });
  }
}
